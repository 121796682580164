<template>
  <div class="search-bar">
    <text-input
      :model-value="value"
      :style="{'width': '100%'}"
      :placeholder="placeholder"
      @input="updateSearchString"
    />
    <search-icon
      class="search-icon"
      size="1.2x"
    />
  </div>
</template>
<script>
import { SearchIcon } from '@zhuowenli/vue-feather-icons';
import TextInput from '../forms/TextInput.vue';

export default {
  components: {
    TextInput,
    SearchIcon,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['updateSearch'],
  methods: {
    updateSearchString(event) {
      this.$emit('updateSearch', event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  width: $search-bar-width;
  min-width: $search-bar-width;;
  display: flex;
  position: relative;
  .search-icon {
    color: $txt-col-grey;
    position: absolute;
    right: 8px;
    height: 100%;
  }
  :deep(.text-input) {
    height: 41px;
    padding-right: 28px;
  }
}
</style>
